:root {
  --primary-color: #92ad16;
  --secondary-color: #292c31;
  --tertiary-color: #19191a;
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--tertiary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: var(--secondary-color);
  border-radius: 20px;
  min-width: 80vw;
  min-height: 60vh;
  max-width: 80vw;
  max-height: 60vh;
  overflow: hidden;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  text-overflow: clip;
}

#password {
  color: var(--primary-color);
  font-family: 'Space Mono', monospace;
}

.form-label {
  margin: auto 1em 0 0;
  font-size: 0.7em;
  color: var(--primary-color);
  font-family: 'Space Mono', monospace;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  appearance: none; /* Override default CSS styles */
  width: 100%; /* Full-width */
  height: 1em; /* Specified height */
  background: var(--tertiary-color); /* Grey background */
  border-radius: 1em;
  padding: 0.5em;
  opacity: 0.5
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width:1.2em; /* Set a specific slider handle width */
  height:1.2em; /* Slider handle height */
  background: var(--primary-color); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width:1.2em; /* Set a specific slider handle width */
  height:1.2em; /* Slider handle height */
  background: var(--primary-color);
  cursor: pointer; /* Cursor on hover */
}


.slider::-webkit-slider-thumb:hover {
  width:1.4em; /* Set a specific slider handle width */
  height:1.4em; /* Slider handle height */
}

.slider::-moz-range-thumb:hover {
  width:1.4em; /* Set a specific slider handle width */
  height:1.4em; /* Slider handle height */
}

.button-68 {
  appearance: none;
  backface-visibility: hidden;
  background-color: var(--primary-color);
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: var(--secondary-color);
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-68:hover {
  background-color: #acca27;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.button-68:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.button-68:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}